<template>
    <div class="container">
        <crud ref="crud" :options="options"></crud>
    </div>
</template>

<script>
export default {
    name: "List",
    data: function () {
        return {
            options: {
                addBtn: true,
                editBtn: true,
                delBtn: true,
                pager: true,
                addUrl: 'league.MemberLevel.add',
                addPerm: 'league_MemberLevel_add',
                editUrl: 'league.MemberLevel.edit',
                editPerm: 'league_MemberLevel_edit',
                delUrl: 'league.MemberLevel.delete',
                delPerm: 'league_MemberLevel_delete',
                listUrl: 'league.MemberLevel.page',
                formLabelWidth: '100px',
                columns: [
                    {prop: "name", label: "名称", required: true},
                    {prop: "level", label: "序号", type: "number", value: 1, required: true},
                    {
                        prop: "icon", label: "图标", type: 'image',
                        width: 72,
                        html: ({row}) => {
                            return row.icon ? `<img class="list-user-avatar" src="${row.icon}" alt="加载失败"/>` : null
                        }
                    },
                    {prop: "commission", label: "佣金(元)", required: true, type: "number"},
                    {prop: "remark", label: "备注",}
                ],
            }
        }
    },
    methods: {}
}
</script>

<style scoped>

</style>
